<template>
  <Loader />
  <Modal v-if="modalState.visible" />
  <Menu :routes="navItems" />
  <router-view />
  <Footer :routes="navItems" />
</template>

<script>
import Menu from '@/components/Menu.vue'
import Modal from '@/components/Modal.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
import { onMounted, provide, reactive, ref, toRefs } from '@vue/runtime-core'

export default {
  name: 'App',

  components: {
    Modal,
    Menu,
    Footer,
    Loader,
  },

  setup() {
    const state = reactive({
      navItems: [{ id: '', Label: '', Url: '' }],
    })

    const LoaderState = ref(true)
    const updateLoaderState = (e) => {
      LoaderState.value = e
    }
    const modalState = reactive({
      visible: false,
      type: 1,
    })
    const updateModalState = (e, type) => {
      modalState.visible = e
      modalState.type = type
    }

    const fetchNavItems = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_STRAPI_URL}/navigatie`)
        const result = await response.json()
        state.navItems = result.items
      } catch (error) {
        console.log(error)
      }
    }

    onMounted(async () => {
      await fetchNavItems()
    })

    provide('globalLoader', LoaderState)
    provide('updateLoaderState', updateLoaderState)
    provide('modal', modalState)
    provide('updateModalState', updateModalState)

    return {
      ...toRefs(state),
      LoaderState,
      updateLoaderState,
      modalState,
      updateModalState,
    }
  },
}
</script>

<style lang="scss">
@use "./scss/vars";

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  @include vars.typography('body', 'description');
}
</style>
