<template>
  <div class="project">
    <article>
      <span class="project__title">
        <slot name="title"></slot>
      </span>

      <p>
        <slot name="description"></slot>
      </p>

      <slot name="button"></slot>
    </article>

    <img :src="cover" :alt="alt" />
  </div>
</template>

<script>
export default {
  name: 'Project',

  props: {
    cover: String,
    alt: String,
  },
}
</script>

<style lang="scss" scoped>
@use '../scss/vars';
@use '../scss/components/button';

.project {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include vars.tablet {
    grid-template-columns: 1fr;
  }

  &__title {
    @include vars.typography('titles', 'sub');
  }

  p {
    max-width: 768px;
    width: 100%;
    @include vars.typography('body', 'description');
    color: vars.$color-charcoal-black;
  }

  .btn {
    width: fit-content;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
}

article {
  display: flex;
  padding: 50px;
  justify-content: center;
  flex-direction: column;

  @include vars.mobile {
    padding: 20px;
  }
}
</style>
