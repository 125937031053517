<template>
  <header v-if="!loading" :class="{ loaded: !loading }" :style="`background-image:url(${about.Header.Omslagfoto.url})`">
    <h1>{{ about.Header.Omslagtekst }}</h1>
  </header>

  <section v-if="!loading">
    <h2>Over ons</h2>

    <article>
      <p>{{ about.Intro }}</p>
      <img :src="about.Foto.url" alt="" />
    </article>
  </section>
</template>

<script>
import { inject, onMounted } from '@vue/runtime-core'
import { reactive, toRefs } from '@vue/reactivity'
export default {
  name: 'Home',

  setup({ emit }) {
    const state = reactive({
      about: null,
      loading: true,
    })

    const updateLoaderState = inject('updateLoaderState', updateLoaderState)

    const fetchAbout = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_STRAPI_URL}/over-ons`)
        const result = await response.json()
        state.about = result
        state.about.Header.Omslagfoto.url = process.env.VUE_APP_STRAPI_URL + state.about.Header.Omslagfoto.url // Set correct url
        state.about.Foto.url = process.env.VUE_APP_STRAPI_URL + state.about.Foto.url // Set correct url
      } catch (error) {
        console.log(error)
      }
    }

    onMounted(async () => {
      await fetchAbout()
      state.loading = false
      updateLoaderState(state.loading)
    })

    return {
      ...toRefs(state),
      updateLoaderState,
    }
  },
}
</script>

<style lang="scss" scoped>
@use '../scss/vars';
@use '../scss/components/button';

header {
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @include vars.tablet {
    min-height: 40vh;
    padding: 20px;
  }

  &:after {
    content: '';
    background: rgba(vars.$color-charcoal-black, 0.5);
    width: 100%;
    height: 70vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    @include vars.tablet {
      height: 40vh;
    }
  }

  &.loaded {
    animation-delay: 0.2s;
    animation: ZoomIn 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-fill-mode: forwards;
  }

  h1 {
    @include vars.typography('titles', 'main');
    color: vars.$color-white;
    position: relative;
    z-index: 2;

    @include vars.tablet {
      text-align: center;
      font-size: 1.5rem;
    }
  }
}

section {
  max-width: 1820px;
  width: 100%;
  padding: 0 20px;
  margin: 150px auto 0;

  @include vars.mobile {
    margin: 80px auto 0;
  }

  h2 {
    @include vars.typography('titles', 'main');
    color: vars.$color-charcoal-black;
    text-align: center;
    margin: 0 0 60px;
    padding: 0;
    display: block;

    @include vars.mobile {
      @include vars.typography('titles', 'heading');
    }
  }

  article {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(355px, 1fr));
    grid-gap: 80px;

    @include vars.tablet {
      grid-gap: 40px;
    }

    @include vars.mobile {
      grid-template-columns: 1fr;
    }

    p {
      @include vars.typography('body', 'description');
    }

    img {
      max-width: 100%;
      border-radius: 10px;
      box-shadow: 0px 0px 10px rgba(vars.$color-charcoal-black, 0.3);
    }
  }
}

@keyframes ZoomIn {
  from {
    transform: scale(1.05);
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
