<template>
  <header v-if="!loading" :class="{ loaded: !loading }" :style="`background-image:url(${page.Omslagfoto.url})`">
    <h1>{{ page.Omslagtekst }}</h1>
    <span class="subtitle">{{ page.Subtekst }}</span>
  </header>

  <section v-if="!loading">
    <h2>Raamdecoratie/horren</h2>
    <div class="product__grid">
      <ProductCard v-for="product in page.Product" :key="product.id" :src="product.Productfoto.url" :href="product.Productlink">
        {{ product.Productnaam }}
      </ProductCard>
    </div>
  </section>

  <section v-if="!loading">
    <h3>Schuifpuien</h3>
    <div class="schuifpui__grid">
      <p>{{ page.Schuifpuien }}</p>
      <img :src="page.SchuifpuienFoto.url" alt="Schuifpuien" />
    </div>
  </section>

  <section v-if="!loading">
    <h4>RAL Kleuren</h4>
    <img :src="page.RAL.url" alt="RAL Kleuren" class="ral__image" />
  </section>
</template>

<script>
import { inject, onMounted } from '@vue/runtime-core'
import { reactive, toRefs } from '@vue/reactivity'
import ProductCard from '@/components/ProductCard.vue'

export default {
  name: 'Home',

  components: {
    ProductCard,
  },

  setup({ emit }) {
    const state = reactive({
      page: {},
      loading: true,
    })

    const updateLoaderState = inject('updateLoaderState', updateLoaderState)

    const fetchPage = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_STRAPI_URL}/producten`)
        const result = await response.json()
        state.page = result
        state.page.Omslagfoto.url = process.env.VUE_APP_STRAPI_URL + state.page.Omslagfoto.url // Set correct url
        state.page.SchuifpuienFoto.url = process.env.VUE_APP_STRAPI_URL + state.page.SchuifpuienFoto.url // Set correct url
        state.page.RAL.url = process.env.VUE_APP_STRAPI_URL + state.page.RAL.url // Set correct url
        setImgPaths()
      } catch (error) {
        console.log(error)
      }
    }

    const setImgPaths = () => {
      for (let i = 0; i < state.page.Product.length; i++) {
        state.page.Product[i].Productfoto.url = process.env.VUE_APP_STRAPI_URL + state.page.Product[i].Productfoto.url
      }
    }

    onMounted(async () => {
      await fetchPage()
      state.loading = false
      updateLoaderState(state.loading)
    })

    return {
      ...toRefs(state),
      updateLoaderState,
    }
  },
}
</script>

<style lang="scss" scoped>
@use '../scss/vars';
@use '../scss/components/button';

header {
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @include vars.tablet {
    min-height: 40vh;
    padding: 20px;
  }

  &:after {
    content: '';
    background: rgba(vars.$color-charcoal-black, 0.5);
    width: 100%;
    height: 70vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    @include vars.tablet {
      height: 40vh;
    }
  }

  &.loaded {
    animation-delay: 0.2s;
    animation: ZoomIn 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-fill-mode: forwards;
  }

  h1 {
    @include vars.typography('titles', 'main');
    color: vars.$color-white;
    position: relative;
    z-index: 2;
    margin-bottom: 10px;

    @include vars.tablet {
      text-align: center;
      font-size: 1.5rem;
    }
  }

  .subtitle {
    @include vars.typography('body', 'small');
    color: vars.$color-white;
    position: relative;
    z-index: 2;
    text-align: center;
  }
}

section {
  h2,
  h3,
  h4 {
    @include vars.typography('titles', 'main');
    color: vars.$color-charcoal-black;
    text-align: center;
    margin: 0 0 60px;
    padding: 0;
    display: block;

    @include vars.mobile {
      @include vars.typography('titles', 'heading');
    }
  }

  .product__grid {
    max-width: 1420px;
    width: 100%;
    padding: 0 20px;
    margin: 150px auto 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 80px;

    @include vars.desktop {
      grid-template-columns: repeat(2, 1fr);
    }

    @include vars.mobile {
      margin: 80px auto 0;
      grid-template-columns: 1fr;
    }
  }

  .schuifpui__grid {
    max-width: 1420px;
    width: 100%;
    padding: 0 20px;
    margin: 150px auto 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 80px;

    img {
      max-width: 100%;
    }

    @include vars.mobile {
      margin: 80px auto 0;
      grid-template-columns: 1fr;
      grid-gap: 40px;
    }
  }

  .ral__image {
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }
}

@keyframes ZoomIn {
  from {
    transform: scale(1.05);
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
