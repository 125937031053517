<template>
  <nav v-if="!loading">
    <div class="nav__logo">
      <router-link to="/"><img :src="logo"/></router-link>
    </div>
    <ul class="nav__items">
      <li v-for="route in routes" :key="route.id">
        <router-link :to="route.Url">{{ route.Label }}</router-link>
      </li>
    </ul>

    <div class="nav__utils no__offerte">
      <div class="nav__sollicitatie" @click="updateModalState(true, 2)" v-if="settings.Sollicitatie">
        <span>Solliciteren</span>
      </div>
      <!-- <div class="nav__offerte" @click="updateModalState(true, 1)">
        <span>Offerte aanvragen</span>
        <img src="../assets/icons/icon_chevron.svg" alt="Offerte aanvragen" />
      </div> -->
    </div>

    <div class="nav__hamburger" :class="{ fixed: menuOpen }" @click="toggleMenu">
      <span :class="{ slideLeft: menuOpen }">Menu</span>
      <img src="../assets/icons/icon_hamburger.svg" alt="Open menu" />
    </div>

    <div class="nav__mobile" :class="{ showNav: menuOpen }">
      <ul class="nav__mobile__items">
        <li v-for="route in routes" :key="route.id">
          <router-link :to="route.Url" @click="menuOpen = false">{{ route.Label }}</router-link>
        </li>
      </ul>

      <div class="nav__mobile__offerte btn" @click="updateModalState(true, 2)" v-if="settings.Sollicitatie">
        <span>Solliciteren</span>
        <img src="../assets/icons/icon_chevron_black.svg" alt="Offerte aanvragen" />
      </div>
      <!-- <br />
      <div class="nav__mobile__offerte btn" @click="updateModalState(true, 1)">
        <span>Offerte aanvragen</span>
        <img src="../assets/icons/icon_chevron_black.svg" alt="Offerte aanvragen" />
      </div> -->
    </div>
  </nav>
</template>

<script>
import { reactive, toRefs } from '@vue/reactivity'
import { inject, onMounted } from '@vue/runtime-core'
export default {
  name: 'Menu',

  props: {
    routes: Array,
  },

  setup(props, { emit }) {
    const state = reactive({
      logo: '',
      loading: true,
      menuOpen: false,
      settings: {},
    })

    const updateLoaderState = inject('updateLoaderState', updateLoaderState)
    const updateModalState = inject('updateModalState', updateModalState)

    const fetchLogo = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_STRAPI_URL}/instellingen`)
        const result = await response.json()
        state.logo = process.env.VUE_APP_STRAPI_URL + result.Logoklein.url
      } catch (error) {
        console.log(error)
      }
    }

    const fetchGlobalSettings = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_STRAPI_URL}/instellingen`)
        const result = await response.json()
        state.settings = result
      } catch (error) {
        console.log(error)
      }
    }

    const toggleMenu = () => {
      state.menuOpen = !state.menuOpen
    }

    onMounted(async () => {
      await fetchLogo()
      await fetchGlobalSettings()
      state.loading = false
      updateLoaderState(state.loading)
    })

    return {
      ...toRefs(state),
      updateModalState,
      toggleMenu,
    }
  },
}
</script>

<style scoped lang="scss">
@use "../scss/vars";

nav {
  background: transparent;
  width: 100%;
  min-height: 80px;
  padding-left: 50px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  @include vars.desktop {
    padding-left: 0;
  }

  .nav {
    &__mobile {
      background: vars.$color-white;
      width: 200px;
      height: 100%;
      padding: 80px 2rem;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 9;
      will-change: transform;
      box-shadow: 0px 0px 10px rgba(vars.$color-charcoal-black, 0.3);
      transform: translateX(200px);
      transition: transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
      display: none;

      @include vars.desktop {
        display: block;
      }

      &.showNav {
        transform: translateX(0);
        transition: transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
      }

      &__offerte.btn {
        padding: 10px;
        font-size: 12px;
        display: flex;
        align-items: center;

        img {
          height: 12px;
          margin-left: 7px;
        }
      }

      &__items {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          margin: 1rem 0;

          &:first-of-type {
            margin: 0 0 1rem;
          }

          a {
            @include vars.typography('body', 'navigation');
            color: vars.$color-charcoal-black;
            text-decoration: none;
          }
        }
      }
    }

    &__hamburger {
      padding: 1.5rem 1rem;
      cursor: pointer;
      z-index: 10;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      display: none;

      &.fixed {
        position: fixed;
        right: 0;
      }

      span {
        @include vars.typography('body', 'navigation');
        color: vars.$color-charcoal-black;
        text-decoration: none;
        transform: translateX(0);
        transition: transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);

        &.slideLeft {
          transform: translateX(-63px);
          transition: transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
      }

      @include vars.desktop {
        display: flex;
      }
    }

    &__logo {
      width: 100px;
      height: 80px;
      padding: 10px;
      background: vars.$color-charcoal-black;

      img {
        max-width: 55px;
        margin: 0 auto;
        display: block;
      }
    }

    &__items {
      list-style: none;
      margin: 0;
      padding: 30px 0 0 0;
      display: flex;
      justify-content: space-between;
      gap: 50px;

      @include vars.desktop {
        display: none;
      }

      li a {
        @include vars.typography('body', 'navigation');
        color: vars.$color-charcoal-black;
        text-decoration: none;
        position: relative;

        &.is-active:after,
        &:after {
          content: '';
          width: 100%;
          height: 2px;
          background: vars.$color-bright-blue;
          position: absolute;
          bottom: -4px;
          left: 0;
          transform: scaleX(0);
          transition: transform 0.2s;
          transform-origin: left;
        }

        &.is-active:after,
        &:hover:after {
          transform: scaleX(1);
          transition: transform 0.2s;
        }
      }
    }

    &__utils {
      width: 415px;
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &.no__offerte {
        width: 160px;
      }
    }

    &__offerte {
      max-width: 255px;
      width: 100%;
      height: 80px;
      background: vars.$color-charcoal-black;
      color: vars.$color-white;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background 0.35s;
      @include vars.typography('body', 'navigation');

      @include vars.desktop {
        display: none;
      }

      &:hover {
        background: vars.$color-bright-blue;
        transition: background 0.35s;
      }

      img {
        margin-left: 20px;
      }
    }

    &__sollicitatie {
      @extend .nav__offerte;
      max-width: 160px;
      background: vars.$color-bright-blue;

      &:hover {
        background: vars.$color-charcoal-black;
        transition: background 0.35s;
      }
    }
  }
}
</style>
