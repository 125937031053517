<template>
  <header>
    <div class="header__intro">
      <h2>{{ companyName }}</h2>
      <h1>{{ title }}</h1>
    </div>

    <aside>
      <p>{{ intro }}</p>
      <div class="header__scrolldown" @click="scrollDown">
        <img src="../assets/icons/icon_arrow-down.svg" alt="" />
      </div>
    </aside>

    <div class="header__project" v-if="!loading">
      <div class="header__project__background" :class="{ loaded: !loading }" :style="`background-image:url(${recentProject.Omslagfoto.url})`"></div>
      <div class="header__project__bar">
        <span>
          {{ recentProject.Dienst.includes('_') ? recentProject.Dienst.split('_').join(' ') : recentProject.Dienst }} | {{ recentProject.Plaatsnaam }}
        </span>

        <router-link to="/projecten" class="btn btn__secondary">bekijk project</router-link>
      </div>
    </div>
  </header>

  <section>
    <h3>{{ servicesTitle }}</h3>

    <div class="services">
      <div class="services__card" v-for="service in services" :key="service.id">
        <span class="services__card__subtitle">{{ service.Dienstnaam }}</span>
        <img :src="'https://api.jarukunststofkozijnen.nl' + service.Icon.url" alt="" />
      </div>
    </div>
  </section>

  <section v-if="page">
    <h4>Onze werkwijze</h4>

    <div class="services">
      <div class="services__card img">
        <img :src="'https://api.jarukunststofkozijnen.nl' + page.Werkwijze.url" alt="" />
      </div>
    </div>
  </section>

  <section v-if="page">
    <h5>Recensies</h5>

    <div class="services nogrid">
      <div class="services__card review" v-for="review in reviews" :key="review.id">
        <span class="services__card__name">{{ review.Naam }}</span>
        <p class="services__card__review">{{ review.Recensie }}</p>
      </div>

      <div class="services__card review" v-if="!reviews.length">
        <span class="services__card__review">Er zijn nog geen recensies..</span>
      </div>
    </div>

    <div class="review__form">
      <span class="color-whatsapp" v-if="send">Uw recensie is verzonden en wacht op goedkeuring.</span>
      <input type="text" id="name" name="name" placeholder="Uw naam.." v-model="reviewData.name" v-if="!send" />
      <textarea
        name="description"
        id="description"
        cols="30"
        rows="10"
        placeholder="Laat ons weten wat u van ons vind!"
        v-model="reviewData.description"
        v-if="!send"
      ></textarea>
      <div class="btn" @click="sendReview" v-if="!send">Verzenden</div>
    </div>
  </section>
</template>

<script>
import { inject, onMounted } from '@vue/runtime-core'
import { reactive, toRefs } from '@vue/reactivity'
import axios from 'axios'

export default {
  name: 'Home',

  setup(props, { emit }) {
    const state = reactive({
      companyName: '',
      title: '',
      intro: '',
      recentProject: [],
      services: [],
      servicesTitle: '',
      loading: true,
      page: {},
      reviewData: {
        name: '',
        description: '',
      },
      reviews: {},
      send: false,
    })

    const updateLoaderState = inject('updateLoaderState', updateLoaderState)

    const fetchHomePage = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_STRAPI_URL}/homepagina`)
        const result = await response.json()

        state.page = result
        state.title = result.Titel
        state.intro = result.Intro
        state.services = result.Diensten
        state.servicesTitle = result.DienstTitel
        state.recentProject = result.projectens[0]
        state.recentProject.Omslagfoto.url = process.env.VUE_APP_STRAPI_URL + state.recentProject.Omslagfoto.url // Set correct url
      } catch (error) {
        console.log(error)
      }
    }

    const fetchGlobalSettings = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_STRAPI_URL}/instellingen`)
        const result = await response.json()
        state.companyName = result.Bedrijfsnaam
      } catch (error) {
        console.log(error)
      }
    }

    const fetchReviews = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_STRAPI_URL}/Recensies`)
        const result = await response.json()
        state.reviews = result
      } catch (error) {
        console.log(error)
      }
    }

    const sendReview = async () => {
      if (state.reviewData.name.length && state.reviewData.description.length) {
        try {
          axios
            .post('https://api.jarukunststofkozijnen.nl/Recensies', {
              Naam: state.reviewData.name,
              Recensie: state.reviewData.description,
              published_at: null,
            })
            .then((response) => {
              if (response.data) {
                state.send = true
              }
            })
        } catch (error) {
          console.log(error)
        }
      }
    }

    const scrollDown = () => {
      document.querySelector('.header__project__bar').scrollIntoView({ behavior: 'smooth' })
    }

    onMounted(async () => {
      await fetchHomePage()
      await fetchGlobalSettings()
      await fetchReviews()
      state.loading = false
      updateLoaderState(state.loading)
    })

    return {
      ...toRefs(state),
      scrollDown,
      updateLoaderState,
      sendReview,
    }
  },
}
</script>

<style lang="scss">
@use '../scss/vars';
@use '../scss/components/button';
@use "../scss/components/inputs" as input;

header {
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'intro project'
    'intro project'
    'aside project';

  @include vars.desktop {
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr auto;
    grid-template-areas:
      'project'
      'intro'
      'aside';
  }

  @include vars.mobile {
    grid-template-rows: 65vh 1fr auto;
  }

  .header {
    &__intro {
      background: vars.$color-white;
      grid-area: intro;
      padding: 80px 20px 80px 50px;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include vars.mobile {
        padding: 50px 20px;
      }

      h1 {
        @include vars.typography('titles', 'big');
        margin: 0;

        @include vars.desktop {
          font-size: 3rem;
        }

        @include vars.mobile {
          font-size: 2rem;
          line-height: 1.5;
        }
      }

      h2 {
        @include vars.typography('titles', 'heading');
        margin: 0 0 40px;

        @include vars.desktop {
          margin: 0 0 1.5rem;
        }
      }

      h1,
      h2 {
        max-width: 80%;

        @include vars.mobile {
          max-width: initial;
        }
      }
    }

    &__project {
      grid-area: project;
      position: relative;

      &__background {
        width: 100%;
        height: 100%;
        filter: blur(3px);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        opacity: 0;
        position: absolute;
        top: 0;
        z-index: -1;

        &.loaded {
          animation-delay: 0.2s;
          animation: ZoomIn 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          animation-fill-mode: forwards;
        }

        &:after {
          content: '';
          width: 100%;
          height: 100%;
          background: rgba(vars.$color-white, 0.5);
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &__bar {
        background: vars.$color-charcoal-black;
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px 50px;
        position: absolute;
        bottom: 0;

        @include vars.mobile {
          padding: 20px;
          height: 140px;
          flex-direction: column;
        }

        span {
          color: vars.$color-white;
          @include vars.typography('body', 'description');
        }
      }
    }
  }

  aside {
    background: vars.$color-starlight-grey;
    grid-area: aside;
    display: flex;
    align-items: center;
    padding: 50px;
    position: relative;

    @include vars.mobile {
      padding: 20px;
    }

    p {
      max-width: 700px;
      width: 100%;
      @include vars.typography('body', 'description');
      line-height: 2.25;

      @include vars.mobile {
        line-height: 1.75;
      }
    }

    .header__scrolldown {
      background: vars.$color-mineshaft-black;
      width: 100px;
      height: 100px;
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      @include vars.desktop {
        width: 60px;
        height: 60px;
      }

      @include vars.mobile {
        display: none;
      }

      img {
        width: 14px;
        height: 18px;
        transform: translateY(0);
        transition: transform ease-in-out 0.2s;
      }

      &:hover img {
        transform: translateY(5px);
        transition: transform ease-in-out 0.2s;
      }
    }
  }
}

section {
  max-width: 1820px;
  width: 100%;
  padding: 0 20px;
  margin: 150px auto 0;

  @include vars.mobile {
    margin: 80px auto 0;
  }

  h3,
  h4,
  h5 {
    @include vars.typography('titles', 'main');
    color: vars.$color-charcoal-black;
    text-align: center;
    margin: 0 0 60px;
    padding: 0;
    display: block;

    @include vars.mobile {
      @include vars.typography('titles', 'heading');
    }
  }

  .review__form {
    max-width: 990px;
    width: 100%;
    margin: 0 auto;

    input[type='text'],
    textarea {
      margin-bottom: 10px;
    }
  }

  .services {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 100px;

    @include vars.mobile {
      grid-gap: 50px;
    }

    &.nogrid {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
      max-width: 990px;
      margin: 0 auto;

      @include vars.mobile {
        grid-template-columns: 1fr;
      }
    }

    &__card {
      background: vars.$color-white;
      width: 100%;
      height: 286px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-wrap: wrap;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0px 0px 10px rgba(vars.$color-charcoal-black, 0.2);

      &.img {
        height: auto;
      }

      &.review {
        max-width: 480px;
        height: auto;
        margin: 0 auto 30px;
        flex-direction: column;
      }

      img {
        max-width: 100%;
      }

      &__subtitle {
        width: 100%;
        @include vars.typography('titles', 'sub');
        color: vars.$color-charcoal-black;
        display: block;
      }

      &__name {
        @include vars.typography('titles', 'sub');
        text-align: left;
      }

      &__review {
        @include vars.typography('body', 'description');
      }
    }
  }
}

@keyframes ZoomIn {
  from {
    transform: scale(1.05);
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
