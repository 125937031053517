<template>
  <div class="overlay">
    <div class="modal">
      <div class="modal__close" @click="updateModalState(false, 1)">
        <img src="../assets/icons/icon_cross.svg" alt="Sluit bericht" />
      </div>

      <span class="modal__subtitle" v-if="!send">{{ modalState.type == 1 ? 'Offerte aanvragen' : 'Solliciteren' }}</span>

      <p v-if="modalState.type == 2 && !send">
        {{ settings.SollicitatieIntro }}
      </p>

      <div class="modal__row" v-if="send">
        <span class="color-whatsapp">Wij hebben uw aanvraag in goede orde ontvangen, wij nemen z.s.m contact met u op.</span>
      </div>

      <div class="modal__row" v-if="!send">
        <label for="name">Naam</label>
        <input
          type="text"
          :class="{ 'error-border': errors.includes('name') }"
          id="name"
          name="name"
          v-model="formData.name"
          @blur="checkInput(formData.name, 'name')"
        />
        <span class="error" v-if="errors.includes('name')">Wij hebben uw naam nodig</span>
      </div>

      <div class="modal__row" v-if="!send">
        <label for="email">E-mailadres</label>
        <input
          type="email"
          :class="{ 'error-border': errors.includes('email') }"
          id="email"
          name="email"
          v-model="formData.email"
          @blur="checkInput(formData.email, 'email')"
        />
        <span class="error" v-if="errors.includes('email')">Dit is een ongeldig e-mailadres</span>
      </div>

      <div class="modal__row" v-if="!send">
        <label for="woonplaats">Woonplaats</label>
        <input
          type="text"
          :class="{ 'error-border': errors.includes('woonplaats') }"
          id="woonplaats"
          name="woonplaats"
          v-model="formData.woonplaats"
          @blur="checkInput(formData.woonplaats, 'woonplaats')"
        />
        <span class="error" v-if="errors.includes('woonplaats')">Uw woonplaats is verplicht</span>
      </div>      

      <div class="modal__row" v-if="!send && modalState.type == 1">
        <label for="service">Gewenste dienst</label>
        <select name="service" id="service" v-model="formData.service">
          <option v-for="service in services" :key="service" :value="service">{{ service }}</option>
        </select>
      </div>

      <div class="modal__row" v-if="!send && modalState.type == 2">
        <label for="file">Upload uw CV</label>
        <input type="file" @change="onFileChange" />
      </div>

      <div class="modal__row" v-if="!send">
        <label for="description">{{ modalState.type == 1 ? 'Korte omschrijving' : 'Motivatie' }}</label>
        <textarea
          :class="{ 'error-border': errors.includes('description') }"
          name="description"
          id="description"
          cols="30"
          rows="10"
          v-model="formData.description"
          @blur="checkInput(formData.description, 'description')"
        ></textarea>
        <span class="error" v-if="errors.includes('description')">De omschrijving moet minimaal 10 karakters lang zijn</span>
      </div>

      <div class="modal__row__col__2" v-if="!send">
        <div>
          <div class="btn" @click="sendQuote" v-if="modalState.type == 1">Versturen</div>
          <div class="btn" @click="sendSollicitatie" v-if="modalState.type == 2">Versturen</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, reactive, toRefs, onMounted } from '@vue/runtime-core'
import { api } from '../../api'

export default {
  name: 'Modal',

  setup() {
    const state = reactive({
      services: ['Kunststof kozijnen', 'Gevelbekleding', 'Raamdecoratie', 'Schuifpuien', 'Complete Renovatie'],
      formData: {
        name: '',
        email: '',
        woonplaats: '',
        service: 'Kunststof kozijnen',
        description: '',
      },
      file: '',
      errors: [],
      send: false,
      settings: {},
    })

    const updateModalState = inject('updateModalState', updateModalState)
    const modalState = inject('modal', modalState)

    const onFileChange = (e) => {
      state.file = e.target.files[0]
      console.log(state.file)
    }

    const checkInput = (val, type) => {
      if (type == 'name' && val.length < 2) {
        return throwError('name')
      } else if (type == 'email' && !val.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
        return throwError('email')
      } else if (type == 'description' && val.length < 10) {
        return throwError('description')
      } else if(type == 'woonplaats') {
        return throwError('woonplaats')
      }

      state.errors = []
    }

    const throwError = (type) => {
      state.errors.push(type)
    }

    const sendQuote = () => {
      if (!state.errors.length && state.formData.name.length && state.formData.email.length && state.formData.description.length) {
        try {
          api
            .post('/sendQuote', {
              formData: state.formData,
            })
            .then((response) => {
              state.send = response.data.val
            })
        } catch (error) {
          console.log(error)
        }
      }
    }

    const sendSollicitatie = async () => {
      let fileName

      if (!state.errors.length && state.formData.name.length && state.formData.email.length && state.formData.description.length && state.file) {
        const fileData = new FormData()
        fileData.append('file', state.file)

        try {
          await api.post('/uploadfile', fileData).then((response) => {
            if (response.data.name) {
              fileName = response.data.name
            }
          })
        } catch (error) {
          console.log(error)
        }

        try {
          await api.post('/sendJob', { formData: state.formData, fileName: fileName }).then((response) => {
            state.send = response.data.val
          })
        } catch (error) {
          console.log(error)
        }
      }
    }

    const fetchGlobalSettings = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_STRAPI_URL}/instellingen`)
        const result = await response.json()
        state.settings = result
      } catch (error) {
        console.log(error)
      }
    }

    onMounted(async () => {
      await fetchGlobalSettings()
    })

    return {
      ...toRefs(state),
      updateModalState,
      modalState,
      onFileChange,
      checkInput,
      sendQuote,
      sendSollicitatie,
    }
  },
}
</script>

<style lang="scss" scoped>
@use "../scss/vars";
@use "../scss/components/inputs" as input;

.overlay {
  background: rgba(vars.$color-charcoal-black, 0.9);
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  .modal {
    max-height: 100vh;
    max-width: 768px;
    width: 100%;
    background: vars.$color-starlight-grey;
    border-radius: 17px;
    padding: 1.875rem;
    position: relative;

    @include vars.mobile {
      p {
        @include vars.typography('body', 'detail');
      }
    }

    &__close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
    }

    &__subtitle {
      color: vars.$color-charcoal-black;
      @include vars.typography('titles', 'sub');
      margin-bottom: 2rem;
      display: block;

      @include vars.mobile {
        @include vars.typography('titles', 'label');
      }
    }

    &__reaction {
      color: vars.$color-whatsapp;
      display: flex;
      align-items: center;

      img {
        width: 1.5rem;
        vertical-align: middle;
        margin-right: 1rem;
      }

      span {
        @include vars.typography('body', 'detail');
      }
    }

    &__row {
      display: block;
      margin: 2rem 0;

      @include vars.mobile {
        margin: 1rem 0;
      }

      @media screen and (max-height: 667px) {
        textarea {
          min-height: 100px;
          max-height: 110px;
        }

        input[type='text'],
        input[type='email'] {
          height: 34px;
        }

        label {
          font-size: 1rem;
        }
      }

      &__col__2 {
        display: flex;
        justify-content: space-between;
      }

      .error {
        @include vars.typography('body', 'detail');
        color: vars.$color-error;

        &-border {
          border-color: vars.$color-error;
        }
      }
    }
  }
}
</style>
