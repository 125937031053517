<template>
  <header v-if="!loading" :class="{ loaded: !loading }" :style="`background-image:url(${page.Omslagfoto.url})`">
    <h1>{{ page.Paginatitel }}</h1>
  </header>

  <section v-if="!loading">
    <Project v-for="(project, prIndex) in projects" :key="project.id" :cover="project.Omslagfoto.url" :alt="`${project.Dienst} ${project.Plaatsnaam}`">
      <template v-slot:title>{{ project.Dienst.split('_').join(' ') }}</template>
      <template v-slot:description>{{ project.Omschrijving }}</template>
      <template v-slot:button><a class="btn" @click="showGallery(prIndex)">Bekijk project</a></template>
    </Project>
  </section>

  <vue-easy-lightbox v-if="!loading" moveDisabled :visible="visible" :imgs="gallery" :index="index" @hide="visible = false"></vue-easy-lightbox>
</template>

<script>
import { inject, onMounted } from '@vue/runtime-core'
import { reactive, toRefs } from '@vue/reactivity'
import Project from '@/components/Project.vue'

export default {
  name: 'Home',

  components: {
    Project,
  },

  setup({ emit }) {
    const state = reactive({
      page: {},
      projects: {},
      gallery: [],
      index: 0,
      visible: false,
      loading: true,
    })

    const updateLoaderState = inject('updateLoaderState', updateLoaderState)

    const fetchPage = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_STRAPI_URL}/projectpagina`)
        const result = await response.json()
        state.page = result
        state.page.Omslagfoto.url = process.env.VUE_APP_STRAPI_URL + state.page.Omslagfoto.url // Set correct url
      } catch (error) {
        console.log(error)
      }
    }

    const fetchProjects = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_STRAPI_URL}/projectens`)
        const result = await response.json()
        state.projects = result
        setImgPaths()
      } catch (error) {
        console.log(error)
      }
    }

    const setImgPaths = () => {
      for (let i = 0; i < state.projects.length; i++) {
        state.projects[i].Omslagfoto.url = process.env.VUE_APP_STRAPI_URL + state.projects[i].Omslagfoto.url

        for (let x = 0; x < state.projects[i].Gallerij.length; x++) {
          state.projects[i].Gallerij[x].url = process.env.VUE_APP_STRAPI_URL + state.projects[i].Gallerij[x].url
        }
      }
    }

    const showGallery = (index) => {
      state.gallery = state.projects[index].Gallerij.map((x) => x.url) // Set all image urls on a new array
      state.index = index
      state.visible = true
    }

    onMounted(async () => {
      await fetchPage()
      await fetchProjects()
      state.loading = false
      updateLoaderState(state.loading)
    })

    return {
      ...toRefs(state),
      updateLoaderState,
      showGallery,
    }
  },
}
</script>

<style lang="scss" scoped>
@use '../scss/vars';
@use '../scss/components/button';

header {
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @include vars.tablet {
    min-height: 40vh;
    padding: 20px;
  }

  &:after {
    content: '';
    background: rgba(vars.$color-charcoal-black, 0.5);
    width: 100%;
    height: 70vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    @include vars.tablet {
      height: 40vh;
    }
  }

  &.loaded {
    animation-delay: 0.2s;
    animation: ZoomIn 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-fill-mode: forwards;
  }

  h1 {
    @include vars.typography('titles', 'main');
    color: vars.$color-white;
    position: relative;
    z-index: 2;

    @include vars.tablet {
      text-align: center;
      font-size: 1.5rem;
    }
  }
}

section {
  max-width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

@keyframes ZoomIn {
  from {
    transform: scale(1.05);
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
