<template>
  <div class="product__card">
    <img :src="src" alt="" class="product__card__image" />

    <span class="product__card__title">
      <slot></slot>
    </span>

    <a :href="href" class="btn" target="_blank">Meer informatie</a>
  </div>
</template>

<script>
export default {
  name: 'ProductCard',

  props: {
    src: String,
    href: String,
  },
}
</script>

<style lang="scss" scoped>
@use '../scss/vars';
@use '../scss/components/button';

.product__card {
  max-width: 330px;
  width: 100%;
  height: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(vars.$color-charcoal-black, 0.4);

  &__image {
    max-width: 100%;
    height: 220px;
    object-fit: contain;
  }

  &__title {
    @include vars.typography('titles', 'sub');
    color: vars.$color-charcoal-black;
    margin: 25px 0;
  }
}
</style>
