<template>
  <header v-if="!loading" :class="{ loaded: !loading }" :style="`background-image:url(${page.Omslagfoto.url})`">
    <h1>{{ page.Omslagtekst }}</h1>
  </header>

  <section v-if="!loading" class="contact__grid">
    <h2>Contact</h2>

    <article>
      <span class="title">{{ page.Vragentitel }}</span>
      <p>{{ page.Vragenintro }}</p>

      <span class="title">Contactgegevens</span>
      <ul>
        <li>{{ global.Bedrijfsnaam }}</li>
        <li>{{ global.Adres }}</li>
        <li>{{ global.Telefoonnummer }}</li>
      </ul>
    </article>

    <aside>
      <div class="modal">
        <div class="modal__row" v-if="send">
          <span class="color-whatsapp">Wij hebben uw aanvraag in goede orde ontvangen, wij nemen z.s.m contact met u op.</span>
        </div>

        <div class="modal__row" v-if="!send">
          <label for="name">Naam</label>
          <input
            type="text"
            :class="{ 'error-border': errors.includes('name') }"
            id="name"
            name="name"
            v-model="formData.name"
            @blur="checkInput(formData.name, 'name')"
        disabled
          />
          <span class="error" v-if="errors.includes('name')">Wij hebben uw naam nodig</span>
        </div>

        <div class="modal__row" v-if="!send">
          <label for="email">E-mailadres</label>
          <input
            type="email"
            :class="{ 'error-border': errors.includes('email') }"
            id="email"
            name="email"
            v-model="formData.email"
            @blur="checkInput(formData.email, 'email')"
            disabled
          />
          <span class="error" v-if="errors.includes('email')">Dit is een ongeldig e-mailadres</span>
        </div>

        <div class="modal__row" v-if="!send">
          <label for="service">Woonplaats</label>
          <input
            type="text"
            :class="{ 'error-border': errors.includes('woonplaats') }"
            id="woonplaats"
            name="woonplaats"
            v-model="formData.woonplaats"
            @blur="checkInput(formData.woonplaats, 'woonplaats')"
    disabled
          />
        </div>
        <span class="error" v-if="errors.includes('woonplaats')">Uw woonplaats is verplicht</span>

        <div class="modal__row" v-if="!send">
          <label for="service">Onderwerp</label>
          <input
            type="text"
            :class="{ 'error-border': errors.includes('subject') }"
            id="subject"
            name="subject"
            v-model="formData.subject"
            @blur="checkInput(formData.subject, 'name')"
disabled
          />
        </div>

        <div class="modal__row" v-if="!send">
          <label for="description">Korte omschrijving</label>
          <textarea
            :class="{ 'error-border': errors.includes('description') }"
            name="description"
            id="description"
            cols="30"
            rows="10"
            v-model="formData.description"
            @blur="checkInput(formData.description, 'description')"
            disabled
          ></textarea>
          <span class="error" v-if="errors.includes('description')">De omschrijving moet minimaal 10 karakters lang zijn</span>
        </div>

        <!-- <div class="modal__row" v-if="!send">
          <div><div class="btn" @click="sendForm">Versturen</div></div>
        </div> -->
      </div>
    </aside>
  </section>
</template>

<script>
import { api } from '../../api'
import { inject, onMounted } from '@vue/runtime-core'
import { reactive, toRefs } from '@vue/reactivity'
export default {
  name: 'Home',

  setup({ emit }) {
    const state = reactive({
      page: {},
      global: {},
      loading: true,
      formData: {
        name: '',
        email: '',
        subject: '',
        woonplaats: '',
        description: '',
      },
      errors: [],
      send: false,
    })

    const updateLoaderState = inject('updateLoaderState', updateLoaderState)

    const fetchPage = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_STRAPI_URL}/contact`)
        const result = await response.json()
        state.page = result
        state.page.Omslagfoto.url = process.env.VUE_APP_STRAPI_URL + state.page.Omslagfoto.url // Set correct url
      } catch (error) {
        console.log(error)
      }
    }

    const fetchGlobalSettings = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_STRAPI_URL}/instellingen`)
        const result = await response.json()
        state.global = result
      } catch (error) {
        console.log(error)
      }
    }

    const checkInput = (val, type) => {
      if (type == 'name' && val.length < 2) {
        return throwError('name')
      } else if (type == 'email' && !val.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
        return throwError('email')
      } else if (type == 'description' && val.length < 10) {
        return throwError('description')
      } else if(type == 'woonplaats') {
        return throwError('woonplaats')
      }

      state.errors = []
    }

    const throwError = (type) => {
      state.errors.push(type)
    }

    const sendForm = () => {
      if (
        !state.errors.length &&
        state.formData.name.length &&
        state.formData.email.length &&
        state.formData.subject.length &&
        state.formData.woonplaats.length &&
        state.formData.description.length
      ) {
        try {
          api
            .post('/sendContactForm', {
              formData: state.formData,
            })
            .then((response) => {
              state.send = response.data.val
            })
        } catch (error) {
          console.log(error)
        }
      }
    }

    onMounted(async () => {
      await fetchPage()
      await fetchGlobalSettings()
      state.loading = false
      updateLoaderState(state.loading)
    })

    return {
      ...toRefs(state),
      updateLoaderState,
      checkInput,
      sendForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@use '../scss/vars';
@use '../scss/components/button';
@use "../scss/components/inputs" as input;

header {
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @include vars.tablet {
    min-height: 40vh;
    padding: 20px;
  }

  &:after {
    content: '';
    background: rgba(vars.$color-charcoal-black, 0.5);
    width: 100%;
    height: 70vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    @include vars.tablet {
      height: 40vh;
    }
  }

  &.loaded {
    animation-delay: 0.2s;
    animation: ZoomIn 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-fill-mode: forwards;
  }

  h1 {
    @include vars.typography('titles', 'main');
    color: vars.$color-white;
    position: relative;
    z-index: 2;

    @include vars.tablet {
      text-align: center;
      font-size: 1.5rem;
    }
  }
}

section {
  max-width: 1820px;
  width: 100%;
  padding: 0 20px;
  margin: 150px auto 0;

  @include vars.mobile {
    margin: 80px auto 0;
  }

  &.contact__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    @include vars.tablet {
      grid-template-columns: 1fr;
    }
  }

  h2 {
    @include vars.typography('titles', 'main');
    color: vars.$color-charcoal-black;
    text-align: center;
    margin: 0 0 60px;
    padding: 0;
    display: block;
    grid-row: 1;
    grid-column: 1 / -1;

    @include vars.mobile {
      @include vars.typography('titles', 'heading');
    }
  }

  article {
    p {
      @include vars.typography('body', 'description');
      margin-bottom: 100px;
    }

    .title {
      @include vars.typography('titles', 'sub');
    }

    ul {
      margin: 20px 0;
      padding: 0;
      list-style: none;

      li {
        display: block;
        margin-bottom: 5px;
      }
    }
  }
}

.modal {
  max-width: 768px;
  width: 100%;
  background: vars.$color-starlight-grey;
  border-radius: 17px;
  padding: 1.875rem;
  position: relative;

  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }

  &__subtitle {
    color: vars.$color-charcoal-black;
    @include vars.typography('titles', 'sub');
    margin-bottom: 2rem;
    display: block;
  }

  &__reaction {
    color: vars.$color-whatsapp;
    display: flex;
    align-items: center;

    img {
      width: 1.5rem;
      vertical-align: middle;
      margin-right: 1rem;
    }

    span {
      @include vars.typography('body', 'detail');
    }
  }

  &__row {
    display: block;
    margin: 2rem 0;

    &__col__2 {
      display: flex;
      justify-content: space-between;
    }

    .error {
      @include vars.typography('body', 'detail');
      color: vars.$color-error;

      &-border {
        border-color: vars.$color-error;
      }
    }
  }
}

@keyframes ZoomIn {
  from {
    transform: scale(1.05);
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
