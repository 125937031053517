<template>
  <footer :class="{ removeMargin: $route.path == '/projecten' }">
    <div class="container">
      <div>
        <img class="container__logo" :src="settings.Logogroot" alt="" />
      </div>

      <div>
        <ul class="container__links">
          <li v-for="route in routes" :key="route.id">
            <router-link :to="route.Url" class="container__links__anchor">{{ route.Label }}</router-link>
          </li>
        </ul>

        <cite>&copy; 2021 {{ settings.Bedrijfsnaam }}</cite>
      </div>

      <div class="container__info">
        <span class="container__info__title">{{ settings.Bedrijfsnaam }}</span>
        <ul class="container__info__list">
          <li>{{ settings.Adres }}</li>
          <li>{{ settings.Telefoonnummer }}</li>

          <!-- <li class="container__info__list__whatsapp">
            <img src="../assets/icons/icon_whatsapp.svg" alt="" />
            <a :href="'https://wa.me/+31' + settings.Telefoonnummer">stuur ons een bericht!</a>
          </li> -->
          <li class="container__info__list__facebook">
            <img src="../assets/icons/icon_facebook.svg" alt="" />
            <a :href="settings.Facebook" target="_blank">bekijk ons op facebook</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import { reactive, toRefs } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
export default {
  name: 'Footer',

  props: {
    routes: Array,
  },

  setup(props) {
    const state = reactive({
      settings: {},
      removeMargin: false,
    })

    const fetchGlobalSettings = async () => {
      const response = await fetch(`${process.env.VUE_APP_STRAPI_URL}/instellingen`)
      const result = await response.json()
      state.settings = result
      state.settings.Logogroot = process.env.VUE_APP_STRAPI_URL + state.settings.Logogroot.url
    }

    onMounted(async () => {
      await fetchGlobalSettings()
    })

    return {
      ...toRefs(state),
    }
  },
}
</script>

<style lang="scss" scoped>
@use '../scss/vars';

footer {
  background: vars.$color-charcoal-black;
  width: 100%;
  min-height: 420px;
  margin-top: 150px;
  padding: 50px 0;

  @include vars.mobile {
    margin-top: 50px;
  }

  &.removeMargin {
    margin-top: 0;
  }

  .container {
    max-width: 1820px;
    width: 100%;
    height: 320px;
    padding: 0 20px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @include vars.mobile {
      width: calc(100% - 20px);
      margin: 0;
      height: 100%;
      grid-template-columns: 1fr;
      grid-row-gap: 50px;
    }

    &__logo {
      max-width: 250px;
      width: 100%;
    }

    &__links {
      margin: 0;
      padding: 0;
      list-style: none;

      li:not(:last-of-type) {
        margin-bottom: 20px;
      }

      &__anchor {
        @include vars.typography('body', 'description');
        color: vars.$color-white;
        text-decoration: none;
        transition: color 0.35s;

        &:hover {
          color: vars.$color-bright-blue;
          transition: color 0.35s;
        }
      }
    }

    &__info {
      &__title {
        @include vars.typography('body', 'description');
        color: vars.$color-white;
      }

      &__list {
        margin: 20px 0 0 0;
        padding: 0;
        list-style: none;

        li {
          @include vars.typography('body', 'detail');
          max-width: 150px;
          color: vars.$color-white;
          margin-bottom: 5px;
        }

        &__whatsapp {
          margin-top: 20px;
          max-width: 100% !important;

          @include vars.mobile {
            width: fit-content;
            border: 1px solid vars.$color-whatsapp;
            padding: 10px 1rem;
            border-radius: 6px;
            margin: 30px auto 10px;
          }

          a {
            color: vars.$color-whatsapp;
            text-decoration: none;
            margin-left: 10px;
            vertical-align: top;
          }
        }

        &__facebook {
          margin-top: 10px;
          max-width: 100% !important;

          @include vars.mobile {
            width: fit-content;
            border: 1px solid vars.$color-facebook;
            padding: 10px 1rem;
            border-radius: 6px;
            margin: 10px auto;
          }

          a {
            color: vars.$color-facebook;
            text-decoration: none;
            margin-left: 18px;
            vertical-align: top;
          }
        }
      }
    }

    & > div:nth-of-type(2) {
      position: relative;
      height: 100%;
    }

    cite {
      color: vars.$color-white;
      @include vars.typography('body', 'detail');
      font-style: normal;
      position: absolute;
      bottom: 0;

      @include vars.mobile {
        display: none;
      }
    }
  }
}
</style>
